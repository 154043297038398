@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');

$primaryBackground:  #000000;
$fontColor: #ffffff;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  list-style: none;
  scroll-behavior: smooth;
  font-family: 'Cabin', sans-serif;
}


.container{
  background-color: $primaryBackground;
  height: 100vh;
  width: 100%;
  color: $fontColor;
  
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: fixed;
  position: fixed;
}
.wrapper{
  width: 90%;
  height: 100vh;
}
.hero{
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}


.hero__left{
  width: 50%;
  padding-top: 5em;
  h1{
    font-size: 65px;
    line-height: 100px;
    text-align: left;
    font-weight: 400;
  }
  p{
    font-size: 20px;
    text-align: left;
    font-weight: 400;
  }
  span{
    color: #FA5805;
  }
  button{
    width: 463px;
    height: 92px;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border: 2px solid #E3B505;
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    background: transparent; 
    margin-top: 1em;
}
  
}

.hero__right{
  background: url(./images/Ellipse.png);
  background-repeat: no-repeat;
  background-size: 630px; 
  background-position-x: 20px;
  background-position-y: 40px;
  background-position: center center;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding-top: 5em;
  img{
    height: 630px;
    width: auto;
  }
}

.nav_modal{
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: $primaryBackground;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: display 0.2s ease-in-out;
  &__links{
    color: #ffffff;
    padding: 1.2em;
    font-size: 36px;
    line-height: 50px;
  }
}

.login{
  width: 100%;
  position: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__left{
    background: rgba(250, 88, 5, 0.1);
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
  }
   &__close{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-top: 1em;
    }
  &__right{
    width: 50%;
    background-color: #000;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    h1{
      font-size: 50px;
      color: #fff;
    }
    
  }
}
.login__hero{
  display: flex;

}

.login__text__logo{
  border-bottom: 2px solid #fff;
  font-size: 25px;
  color: #ffffff;
  width: 80%;
  height: 50px;
}

.login__bottom{
  width: 80%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-top: 7em;
}

.login__hero{
  color: #FA5805;
}
.login__hero p{
  font-weight: bold;
  font-size: 35px;
  line-height: 43px;
}
.login__form{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 60vh;
}
.login__form form{
  width: 80%;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  text-align: left;
}
.login__btn{
  background: #FFFFFF;
  border-radius: 0px 30px 30px 0px;
  color: #000;
  width: 200px;
  height: 60px;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  outline: none;
  border: none;
  align-self: flex-end;
  margin-right: 3em;
}
.login__form input{
  width: 80%;
  height: 50px;
  background: none;
  border: none;
  &::placeholder{
    font-size: 18px;
    margin-left: 1em;
  }
}
.login__label{
      color: #ffffff;
      font-weight: 500;
      font-size: 30px;
      text-align: left;
    }

@media screen and (max-width: 460px){
  .container{
    text-align: center;
    height: 100vh;
  }
  .hero{
    flex-direction: column;
    position: relative;
    margin-top: 1em;
  }
  .hero__left{
    width: 100%;
    text-align: center;
    padding-top: 1em;
    h1{
      font-size: 35px;
      line-height: 50px;
      text-align: center;
    }
    p{
      font-size: 14px;
      text-align: center;
      line-height: 30px;
    }
    button{
      width: 150px;
      height: 40px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 1em;
    }
  }
  .hero__right{
    width: 100%;
    background: none;
    position: absolute;
    top: 21em;
    justify-content: center;
    align-items: center;
    
  }

  .login{

    &__left{
    display: none;
    }
    &__right{
    width: 100%;
    }
    &__close{
      
      width: 90%;
      margin-top: 1em;
    }
  }

  .login__hero p {
    font-size: 18px;
  }
  .login__btn{
    width: 168px;
    height: 45px;
    font-size: 20px;
    margin-right: 1em;
  }
  .login__label{
    font-size: 18px;
  }
  .login__text__logo{
    height: 30px;
    width: 75%;
  }
  .login__bottom{
    width: 80%;
    }
    .login__bottom{
      margin-top: 3em;
    }
  
}
